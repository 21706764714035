/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import {
  FaSync,
  FaSearch,
  FaEye,
  FaTrash,
  FaPencilAlt,
  FaRegCopy,
} from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const EppsiIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  const [eppsi, setEppsi] = useState([]);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);

  const [tglMulai, setTglMulai] = useState("");
  const [tglAkhir, setTglAkhir] = useState("");
  const [idOutlet, setIdOutlet] = useState("");

  const [outlet, setOutlet] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const btnLoading = isLoading ? "is-loading" : "";

  const [isDownload, setIsDownload] = useState(false);
  const btnDownload = isDownload ? "is-loading" : "";

  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [field, setField] = useState("namaUser");
  const [fieldQuery, setFieldQuery] = useState("namaUser");
  const [dateFirst, setDateFirst] = useState("");
  const [dateSecond, setDateSecond] = useState("");
  const [idOutletQuery, setIdOutletQuery] = useState("");

  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    refreshToken();
    getAllEppsi();
    allOutlet();
  }, [page, keyword, fieldQuery, dateFirst, dateSecond, idOutletQuery]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllEppsi = async () => {
    setIsLoading(true);
    await axiosJWT
      .get(
        `${API_URL_AUTH}/admin/doceppsi?field_query=${fieldQuery}&search_query=${keyword}&date_first=${dateFirst}&date_second=${dateSecond}&outlet=${idOutletQuery}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setEppsi(response.data.data);
        setPage(response.data.page);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
      });
  };

  const allOutlet = async () => {
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/all-outlet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setOutlet(response.data.data);
      });
  };

  const deleteEppsi = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosJWT
          .delete(`${API_URL_AUTH}/admin/doceppsi/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            getAllEppsi();
            Swal.fire("Deleted!", response.data.message, "success");
          })
          .catch((error) => {
            Swal.fire("Deleted!", error.response.data.message, "error");
          });
      }
    });
  };

  const changePage = ({ selected }) => {
    setPage(selected);
    if (selected === 9) {
      setMsg(
        "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
      );
    } else {
      setMsg("");
    }
  };

  const convertDate = (date) => {
    let dateConv = new Date(date);
    var milliseconds = dateConv.getTime();

    return milliseconds;
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
    setFieldQuery(field);
    setShow(true);

    setDateFirst(convertDate(tglMulai));
    setDateSecond(convertDate(tglAkhir));
    setIdOutletQuery(idOutlet);
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Data Eppsi
            </Link>
          </li>
        </ul>
      </nav>
      <form onSubmit={searchData} className="mb-5">
        <div className="columns is-desktop">
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Nama Petugas</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="text"
                      className="input is-small"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      placeholder="keyword..."
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Tanggal</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="datetime-local"
                      className="input is-small"
                      value={tglMulai}
                      onChange={(e) => setTglMulai(e.target.value)}
                      placeholder="0000-00-00 00:00:00"
                    />
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="datetime-local"
                      className="input is-small"
                      value={tglAkhir}
                      min={tglMulai}
                      onChange={(e) => setTglAkhir(e.target.value)}
                      placeholder="0000-00-00 00:00:00"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Outlet</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <span className="select is-small is-fullwidth">
                      <select
                        value={idOutlet}
                        onChange={(e) => setIdOutlet(e.target.value)}
                      >
                        <option key={`peru-00`} value="">
                          - Semua Outlet -
                        </option>
                        {outlet.map((outlet, index) => (
                          <option key={`peru-${index}`} value={outlet.id}>
                            {outlet.namaOutlet}
                          </option>
                        ))}
                      </select>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="buttons is-fullwidth is-centered has-background-light pt-2">
          <button
            type="submit"
            className={`button is-info is-small is-rounded ${btnLoading}`}
          >
            <span className="icon">
              <FaSearch />
            </span>
            <span>Filter Data</span>
          </button>
          {/* <button
            type="button"
            onClick={() => downloadCsv()}
            className={`button is-success is-light is-rounded is-small ${btnDownload}`}
          >
            <span className="icon">
              <FaFileExcel />
            </span>
            <span className="is-hidden-mobile">Download CSV</span>
          </button> */}
          <a
            href="/doceppsi"
            className="button is-link is-small is-rounded is-light is-right"
          >
            <span className="icon">
              <FaSync />
            </span>
            <span className="is-hidden-mobile">Refresh</span>
          </a>
        </p>
      </form>

      {show ? (
        <>
          {eppsi ? (
            <>
              <div className="table-container">
                <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
                  <thead className="table-header">
                    <tr>
                      <th>No.</th>
                      <th>UrlDoc</th>
                      <th>Total Sim</th>
                      <th>Petugas</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eppsi.map((eppsi, index) => (
                      <tr key={eppsi.id}>
                        <td>{page * limit + index + 1}</td>
                        <td>{eppsi.urlDoc}</td>
                        <td>{eppsi.totalSim}</td>
                        <td>
                          <strong>{eppsi.namaUser}</strong>
                          <br />
                          -----
                          <br />
                          {eppsi.namaOutlet}
                        </td>
                        <td>{eppsi.createdAt}</td>
                        <td>{eppsi.updatedAt}</td>
                        <td>
                          <Link
                            key={`edit-${index}`}
                            to={`${eppsi.id}`}
                            className="button is-link is-succes is-rounded is-small"
                          >
                            <span className="icon">
                              <FaPencilAlt />
                            </span>
                            <span className="is-hidden-mobile">Edit</span>
                          </Link>
                          <Link
                            key={`copy-${index}`}
                            to={`copy/${eppsi.id}`}
                            className="button is-link is-warning is-rounded is-small"
                          >
                            <span className="icon">
                              <FaRegCopy />
                            </span>
                            <span className="is-hidden-mobile">Copy</span>
                          </Link>
                          <a
                            href={eppsi.urlDoc}
                            className="button is-link is-light is-rounded is-small"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="icon">
                              <FaEye />
                            </span>
                            <span className="is-hidden-mobile">View</span>
                          </a>
                          <button
                            key={`del-${index}`}
                            onClick={() => deleteEppsi(eppsi.id)}
                            className="button is-small is-rounded is-danger is-light"
                          >
                            <span className="icon">
                              <FaTrash />
                            </span>
                            <span className="is-hidden-mobile">Hapus</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="has-text-centered has-text-danger">{msg}</p>
              <div className="columns">
                <div className="column">
                  <span className="is-size-7">
                    Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}
                  </span>
                </div>
                <div className="column">
                  <nav
                    className="pagination is-rounded is-small is-right"
                    key={rows}
                    role="navigation"
                    aria-label="pagination"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      pageCount={Math.min(10, pages)}
                      onPageChange={changePage}
                      containerClassName={"pagination-list"}
                      pageLinkClassName={"pagination-link"}
                      previousLinkClassName={"pagination-previous"}
                      nextLinkClassName={"pagination-next"}
                      activeLinkClassName={"pagination-link is-current"}
                      disabledLinkClassName={"pagination-link is-disabled"}
                    />
                  </nav>
                </div>
              </div>
            </>
          ) : (
            <div className="is-fullwidth has-text-centered mt-5">
              <figure className="image is-2by2 is-inline-block">
                <img
                  src="https://plan.literasikitaindonesia.com/kasir/no-data-found.png"
                  alt="logo cart"
                />
              </figure>
              <h4>Data tidak ditemukan</h4>
            </div>
          )}
        </>
      ) : (
        <div className="is-fullwidth has-text-centered mt-5">
          <img
            src="https://plan.literasikitaindonesia.com/psisim/please-filter2.png"
            alt="logo cart"
            width={380}
          />
          <h4>Silahkan filter data terlebih dahulu</h4>
        </div>
      )}
    </>
  );
};

export default EppsiIndex;
