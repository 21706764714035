import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { FaSave, FaTimes, FaMinusCircle, FaCheck } from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const EpsiCopy = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [errors, setErrors] = useState([]);

  const { id } = useParams();
  const [urlDoc, setUrlDoc] = useState("");
  const [totalSim, setTotalSim] = useState("");
  const [namaUser, setNamaUser] = useState("");
  const [namaOutlet, setNamaOutlet] = useState("");

  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);

  const [outletId, setOutletId] = useState("");
  const [outlets, setOutlets] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    allOutlet();
    allPetugas();
    getEppsiById();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const getEppsiById = async () => {
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/doceppsi/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUrlDoc(response.data.data.urlDoc);
        setTotalSim(response.data.data.totalSim);
        setNamaUser(response.data.data.namaUser);
        setOutletId(response.data.data.outletId);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
  };

  const allOutlet = async () => {
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/all-outlet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setOutlets(response.data.data);
      });
  };
  const allPetugas = async () => {
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/all-petugas`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsers(response.data.data);
      });
  };

  const SimpanEppsi = async (e) => {
    e.preventDefault();

    await axiosJWT
      .post(
        `${API_URL_AUTH}/admin/doceppsi`,
        {
          urlDoc: urlDoc,
          totalSim: totalSim,
          namaOutlet: namaOutlet,
          namaUser: namaUser,
          outletId: outletId,
          userId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setUrlDoc("");
        setTotalSim("");
        setNamaUser("");
        setOutletId("");
        setErrors([]);

        Swal.fire({
          title: "Sukses!",
          text: response.data.message,
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "OK, Kembali ke menu!",
          cancelButtonText: "Tutup, Input data lagi!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          setErrors(error.response.data.errors);
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      });
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const checkErrorInput = (errors, param) => {
    const found = errors.find((error) => error.param === param);
    if (found) {
      return found;
    } else {
      return false;
    }
  };

  return (
    <div className="columns">
      <div className="column is-three-fifths">
        <form onSubmit={SimpanEppsi}>
          <div className="field is-horizontal">
            <div className="field-label is-small">
              <label className="label">Url Document</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-right">
                  <input
                    className="input is-small"
                    type="text"
                    value={urlDoc}
                    onChange={(e) => setUrlDoc(e.target.value)}
                    placeholder="Url Doc"
                    disabled
                  />
                  {checkErrorInput(errors, "urlDoc") ? (
                    <span className="icon is-small is-right has-text-danger">
                      <FaMinusCircle />
                    </span>
                  ) : (
                    <span className="icon is-small is-right">
                      <FaCheck />
                    </span>
                  )}
                </p>
                <p className="help">Url Document Sim pada Eppsi</p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-small">
              <label className="label">Outlet</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control has-icons-right">
                  <select
                    value={outletId}
                    onChange={(e) => setOutletId(e.target.value)}
                    className="select is-small is-fullwidth"
                  >
                    <option value="">- Pilih Outlet -</option>
                    {outlets.map((outlet, index) => (
                      <option key={`out-${index}`} value={outlet.id}>
                        {outlet.namaOutlet}
                      </option>
                    ))}
                  </select>
                  {checkErrorInput(errors, "outletId") ? (
                    <span className="icon is-small is-right has-text-danger">
                      <FaMinusCircle />
                    </span>
                  ) : (
                    <span className="icon is-small is-right">
                      <FaCheck />
                    </span>
                  )}
                </div>
                <p className="help">Pilih outlet tempat bekerja petugas</p>
              </div>
              <div className="field">&nbsp;</div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-small">
              <label className="label">Nama Petugas</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control has-icons-right">
                  <select
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    className="select is-small is-fullwidth"
                  >
                    <option value="">- Pilih Petugas -</option>
                    {users.map((user, index) => (
                      <option key={`out-${index}`} value={user.id}>
                        {user.namaOutlet}
                      </option>
                    ))}
                  </select>
                  {checkErrorInput(errors, "outletId") ? (
                    <span className="icon is-small is-right has-text-danger">
                      <FaMinusCircle />
                    </span>
                  ) : (
                    <span className="icon is-small is-right">
                      <FaCheck />
                    </span>
                  )}
                </div>
                <p className="help">Pilih outlet tempat bekerja petugas</p>
              </div>
              <div className="field">&nbsp;</div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-small">
              <label className="label">Total SIM</label>
            </div>
            <div className="field-body">
              <div className="field has-addons">
                <p className="control has-icons-right">
                  <input
                    className="input is-small"
                    type="text"
                    value={totalSim}
                    onChange={(e) => setTotalSim(e.target.value)}
                    placeholder="NIK"
                  />
                  {checkErrorInput(errors, "nik") ? (
                    <span className="icon is-small is-right has-text-danger">
                      <FaMinusCircle />
                    </span>
                  ) : (
                    <span className="icon is-small is-right">
                      <FaCheck />
                    </span>
                  )}
                </p>
              </div>

              <div className="field">&nbsp;</div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label">&nbsp;</div>
            <div className="field-body">
              <div className="field is-grouped">
                <div className="control">
                  <button
                    type="submit"
                    className="button is-success is-small is-rounded"
                  >
                    <span className="icon">
                      <FaSave />
                    </span>
                    <span>Update Eppsi</span>
                  </button>
                </div>
                <div className="control">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="button is-danger is-light is-small is-rounded"
                  >
                    <span className="icon">
                      <FaTimes />
                    </span>
                    <span>Batalkan</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr />
        {errors.map((error, index) => (
          <p key={`error-${index}`} className="has-text-danger is-size-7">
            <FaMinusCircle /> {error.msg}
          </p>
        ))}
      </div>
    </div>
  );
};

export default EpsiCopy;
